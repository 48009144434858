<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">
        <b-link class="text-dark " to="/payments">PAYMENTS</b-link
        ><b-icon-chevron-right></b-icon-chevron-right>
        <a class="text-primary font-weight-bold">SCHEDULES</a>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button
          v-if="can('create_schedule', 'payments')"
          squared
          variant="primary"
          @click="create_payment_schedule"
        >
          <b-icon-plus></b-icon-plus>New Schedule
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <div class="row m-0">
        <div class="d-flex col">
          <b-select
            class="form-control form-select mr-2"
            variant="light"
            v-model="criteria.status"
            :options="payment_statuses"
            v-analytics:change="[
              'filter_payment_status',
              { element: 'Payments' },
            ]"
            required
          >
          </b-select>
          <b-form class="ml-auto-">
            <b-form-input
              placeholder="Search Name"
              type="search"
              class="search m-0"
              v-analytics:change="[
                'search_payment_supplier',
                { element: 'Payments' },
              ]"
              v-model="criteria.searchfield"
            ></b-form-input>
          </b-form>
          <div class="pl-2">
            <date-picker @dates-selected="cfilterByDate"></date-picker>
          </div>
        </div>
        <div class="col-auto ">
          <div class="sidebar-width text-right">
            <button class="btn-opt primary" @click="get_payment_schedules">
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button class="btn-opt primary" v-print="'#SchedulesTable'">
              <b-icon-printer></b-icon-printer> Print
            </button>
            <!-- <button class="btn-opt primary" ><b-icon-book></b-icon-book> Export </button> -->
            <download-excel
              class=" btn-opt btn  primary"
              :data="items"
              type="csv"
            >
              <b-icon-book></b-icon-book> Export
            </download-excel>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <b-container v-show="loading">
        <b-row class="d-flex justify-content-center p-3">
          <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Large Spinner"
          ></b-spinner>
        </b-row>
      </b-container>
      <div v-show="items.length > 0">
        <b-table
          id="SchedulesTable"
          :fields="fields"
          :items="items"
          :filter="criteria"
          sticky-header="75vh"
          head-variant="light"
          :filter-function="filterItems"
          hover
          thead-class="bg-primary-tb text-primary-tb border-primary-tb"
        >
          <template #cell(name)="data">
            <b-avatar icon="calendar">{{ data.item.name[0] }}</b-avatar>
            {{ data.item.name }}
          </template>
          <template #cell(total_amount)="data" class="text-right">
            <b> {{ data.item.total_amount.toLocaleString() }}</b>
          </template>
          <template #cell(paytype)="data">
            <b-link>{{ data.item.paytype }}</b-link>
          </template>
          <template #cell(time)="data">
            <span class="font-weight-bold small">
              {{ format_date(data.item.created_at) }}
            </span>
          </template>
          <template #cell(approved_date)="data">
            <span class="font-weight-bold small"
              >{{
                format_date(
                  data.item.approval_date
                    ? data.item.approval_date
                    : data.item.approved_at
                )
              }}
            </span>
          </template>
          <template #cell(payment_date)="data">
            <span class="font-weight-bold small">
              {{
                format_date(
                  data.item.payment_date
                    ? data.item.payment_date
                    : data.item.authorized_at
                )
              }}
            </span>
          </template>
          <template #cell(status)="data">
            {{ data.item.approval_status.replace("_", " ") }}
          </template>
          <template #cell(status_symbol)="data">
            <b-icon-exclamation-circle
              v-show="data.item.approval_status == 'pending_verification'"
              variant="danger"
              class="font-justify-end float-right"
            ></b-icon-exclamation-circle>
            <b-icon-exclamation-circle-fill
              variant="warning"
              v-show="data.item.approval_status == 'pending_approval'"
              class="font-justify-end float-right"
            >
            </b-icon-exclamation-circle-fill>
            <b-icon-check-circle-fill
              variant="success"
              v-show="data.item.approval_status == 'fully_paid'"
              class="font-justify-end float-right"
            >
            </b-icon-check-circle-fill>

            <b-icon-check-circle
              variant="primary"
              class="font-justify-end float-right"
              v-show="data.item.approval_status == 'partially_paid'"
            >
            </b-icon-check-circle>
          </template>
          <template #cell(link)="data">
            <!-- :variant="schedule_class(data.item.approval_status)"  -->
            <b-button
              :to="{ name: 'schedule', params: { schedule_id: data.item.id } }"
            >
              {{ schedule_action(data.item.approval_status) }}
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
    <add-payment-schedule @Successful="reload()"></add-payment-schedule>
  </div>
</template>

<script>
import {
  get_payment_schedules,
  schedule_class,
  schedule_action,
  format_date,
} from "./actions.js";
import { bus } from "@/main.js";
import { can } from "@/modules/auth/utils.js";
import moment from "moment";
import AddPaymentSchedule from "@/components/add_payment_schedule/AddPaymentSchedule.vue";
import DatePicker from "@/modules/produce/components/ProduceDatePicker.vue";
import dateMixin from "@/modules/produce/date_mixin";

export default {
  components: { AddPaymentSchedule, DatePicker },
  mixins: [dateMixin],
  computed: {
    url() {
      return (
        process.env.VUE_APP_BACKEND +
        "/api/v3/" +
        this.$store.state.project_id +
        this.$store.state.url_prefix
      );
    },
  },
  data() {
    let searchfield = "";
    let fields = [
      { key: "tag", label: "Ref" },
      { key: "time", label: "Date" },
      { key: "farmers", label: "Farmers" },
      {
        key: "total_amount",
        label: "Total Amount",
        tdClass: "text-right",
        thClass: "text-right",
      },
      { key: "status", label: "Status" },
      { key: "status_symbol", label: "" },
      { key: "approved_date", label: "Verified On" },
      { key: "payment_date", label: "Payment Date" },
      { key: "link", label: "", tdClass: "table-control" },
    ];
    let schedule_data = {
      load: true,
    };
    return {
      searchfield,
      fields,
      isAdding: false,
      items: [],
      loading: false,
      crops: [],
      payment_status: "",
      allschedules: [],
      payment_statuses: [
        { value: null, text: "All" },
        { value: "pending_verification", text: "Pending Verification" },
        { value: "pending_approval", text: "Pending Approval" },
        { value: "fully_paid", text: "Fully paid" },
        { value: "partially_paid", text: "Partially Paid" },
      ],
      schedule_data,
      criteria: {
        status: null,
        searchfield: null,
        selectedPeriod: [""],
      },
    };
  },

  mounted() {
    this.reload();
    this.dateInit();
  },

  methods: {
    get_payment_schedules,
    format_date,
    schedule_class,
    schedule_action,
    can,
    reload() {
      this.get_payment_schedules();
    },
    create_payment_schedule() {
      bus.$emit("add-payment-schedule", { load: true });
    },
    filterItems(row, criteria) {
      let filters = [];
      if (criteria.status) {
        filters.push(row.approval_status == criteria.status);
      }
      if (criteria["selectedPeriod"].length==2) {
        filters.push(
          moment(row["created_at"]).isBetween(...this.criteria.selectedPeriod)
        );
      }
      if (criteria.searchfield) {
        filters.push(
          row["tag"].toLowerCase().includes(criteria.searchfield.toLowerCase())
        );
      }
      return !filters.includes(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  tbody {
    tr {
      td:last-child {
        text-align: right !important;
        background-color: red;
      }
    }
  }
}
</style>
