import { axiosApiInstance } from "@/plugins/axios";
import moment from 'moment';

function get_payment_schedules() {

  this.loading = true

  axiosApiInstance({
    url: this.url+"/payments/schedules",
    method: "get",
  })
    .then((res) => {
      this.items= this.allschedules = res.data.data;
      // console.log(res.status);
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
    });
  // axios.get
}
function schedule_action(status){
  let actions ={
    pending_verification:'Verify',
    pending_approval:'Approve',
    fully_paid:'View',
    partially_paid:'View',
  }
  return actions[status]?actions[status]:'View'
}

function schedule_class(status){
  let classes ={
    pending_verification:'outline-primary',
    pending_approval:'outline-success',
    fully_paid:'success',
    partially_paid:'success',
  }
  return classes[status]?classes[status]:'View'
}

function format_date(date) {
  return date?moment(date,"YYYY-MM-DD HH:mm:ss").format('llll'):'-';
}



export { 
  get_payment_schedules, schedule_action,
  format_date, schedule_class, 
};
